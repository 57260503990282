import { Navigate, Outlet } from 'react-router-dom';
import {getAuthenticationStatus} from "../utils/authentication";

const PrivateRoute = () => {
    return  getAuthenticationStatus() ? (
        <Outlet/>
    ) : (
        <Navigate to="/login" replace />
);
};

export { PrivateRoute };

