import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import {PrivateRoute} from "./components/ProtectedRoute";
import SetPassword from "./components/Set-Password";
import Applications from "./pages/Applications";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <div className="App">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<Login/>}/>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/applications" element={<Applications/>}/>
                    </Route>
                    <Route path="/user/set-password" element={<SetPassword/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
