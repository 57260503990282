import {useEffect, useState} from "react";
import {prettyPrintJson} from 'pretty-print-json';

export default function Home() {
    const [title, setTitle] = useState("Reading your mails");
    const [data, setData] = useState({})
    useEffect(() => {
        fetch("https://backend.thejoboverflow.com/user/extract-data-from-email", {
            credentials: "include",
            cache: "force-cache",
        }).then((r) => {
            if (!r.ok) throw new Error("Failed to fetch, api calls may be exhausted")
            return r.json()
        }).then((d) => {
            setTitle("Here is the extracted information");
            setData(d);
        }).catch((error) => alert(error.message))

    }, [])

    useEffect(() => {
        let span = document.getElementById("account")
        if (span && data.toString().length > 10) span.innerHTML = prettyPrintJson.toHtml(data);
    }, [data]);

    return (
        <div>
            <h1>{title}</h1>
        </div>
    );
}