import React from "react";

export default function ZohoSVGLogo() {
    return <svg viewBox="0 -167.5 512 512" width = {50} version="1.1" xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid" fill="#000000">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <defs>
                <linearGradient x1="49.9958559%" y1="1.43117746%" x2="49.9958559%" y2="96.9811775%"
                                id="linearGradient-1">
                    <stop stop-color="#FFE513" offset="0.5618%"></stop>
                    <stop stop-color="#FDB924" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="4.51245499%" y1="13.4942105%" x2="95.672455%" y2="87.0642105%"
                                id="linearGradient-2">
                    <stop stop-color="#008CD2" offset="0.5618%"></stop>
                    <stop stop-color="#00649D" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="50.0016382%" y1="27.7788157%" x2="50.0016382%" y2="97.5288157%"
                                id="linearGradient-3">
                    <stop stop-color="#26A146" offset="0%"></stop>
                    <stop stop-color="#008A52" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="43.733871%" y1="8.20779605%" x2="57.543871%" y2="93.1977961%" id="linearGradient-4">
                    <stop stop-color="#D92231" offset="0%"></stop>
                    <stop stop-color="#BA2234" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g>
                <g transform="translate(384.000000, 37.000000)">
                    <path d="M128,0.7 L128,122.1 L111.2,138.5 L111.2,19.7 L128,0.7 L128,0.7 Z" fill="#E79225"></path>
                    <path d="M17.9,0.8 L0.5,19.7 L1.4,20.9 L110.5,20.5 L111.5,19.7 L128,0.7 L17.9,0.8 L17.9,0.8 Z"
                          fill="#FFF16D"></path>
                    <rect fill="url(#linearGradient-1)" x="0.5" y="19.7" width="111" height="118.9"></rect>
                    <path
                        d="M94.6,62.5 C92.4,57 89.1,52 84.8,47.7 C80.7,43.5 76.1,40.3 70.9,38.2 C65.8,36.1 60.3,35 54.3,35 C48.3,35 42.7,36.1 37.6,38.2 C32.4,40.3 27.8,43.5 23.7,47.7 C19.4,52 16.2,57 14,62.5 C11.8,68 10.8,74 10.8,80.6 C10.8,87 11.9,93 14.1,98.6 C16.3,104.2 19.5,109.2 23.8,113.6 C27.8,117.7 32.4,120.8 37.5,122.9 C42.6,125 48.3,126.1 54.4,126.1 C60.3,126.1 65.8,125 70.9,122.9 C76,120.8 80.7,117.7 84.8,113.6 C89.1,109.2 92.4,104.2 94.6,98.7 C96.8,93.2 97.9,87.1 97.9,80.7 C97.9,74 96.8,68 94.6,62.5 L94.6,62.5 L94.6,62.5 Z M71.7,101.7 C67.4,106.8 61.7,109.4 54.3,109.4 C46.9,109.4 41.1,106.8 36.8,101.7 C32.5,96.6 30.4,89.5 30.4,80.5 C30.4,71.3 32.6,64.2 36.8,59 C41.1,53.8 46.8,51.3 54.3,51.3 C61.7,51.3 67.4,53.9 71.7,59 C75.9,64.2 78.1,71.3 78.1,80.5 C78.1,89.5 76,96.6 71.7,101.7 L71.7,101.7 L71.7,101.7 Z"
                        fill="#FFFFFF"></path>
                </g>
                <g transform="translate(257.000000, 27.000000)">
                    <path
                        d="M116.6,0.8 L116.6,1.4 L130.8,110.5 L122.5,133.5 L121.4,132.7 L106.8,28.1 L107.1,26.7 L116.2,1.4 L116.6,0.8 L116.6,0.8 Z"
                        fill="#009ADA"></path>
                    <path d="M7.3,16 L116.6,0.8 L107.4,27 L106.1,28.4 L3.9,43.4 L4.4,24.7 L7.3,16 L7.3,16 Z"
                          fill="#91C9ED"></path>
                    <path d="M107.4,27 L122.5,133.5 L14.8,148.6 L0.3,45.7 L6.6,40.8 L107.4,27 L107.4,27 Z"
                          fill="url(#linearGradient-2)"></path>
                    <path
                        d="M89.1,47.4 C88.6,44.1 87.5,41.6 85.7,39.9 C84.2,38.6 82.4,37.9 80.3,37.9 C79.8,37.9 79.2,37.9 78.6,38 C75.8,38.4 73.7,39.7 72.4,41.8 C71.4,43.3 71,45.2 71,47.4 C71,48.2 71.1,49.1 71.2,50 L75.1,77.7 L44.1,82.3 L40.2,54.6 C39.7,51.4 38.6,48.9 36.8,47.2 C35.3,45.8 33.5,45.1 31.5,45.1 C31,45.1 30.5,45.1 30,45.2 C27.1,45.6 24.9,46.9 23.5,49 C22.5,50.5 22.1,52.4 22.1,54.6 C22.1,55.4 22.2,56.3 22.3,57.3 L32.9,129.4 C33.4,132.7 34.5,135.2 36.5,136.9 C38,138.2 39.8,138.8 42,138.8 C42.6,138.8 43.2,138.8 43.8,138.7 C46.5,138.3 48.5,137 49.8,134.9 C50.7,133.4 51.1,131.6 51.1,129.5 C51.1,128.7 51,127.8 50.9,126.9 L46.6,98.4 L77.6,93.8 L81.9,122.3 C82.4,125.6 83.5,128.1 85.4,129.7 C86.9,131 88.7,131.7 90.8,131.7 C91.3,131.7 91.9,131.7 92.5,131.6 C95.3,131.2 97.4,129.9 98.7,127.8 C99.6,126.3 100.1,124.5 100.1,122.3 C100.1,121.5 100,120.6 99.9,119.7 L89.1,47.4 L89.1,47.4 Z"
                        fill="#FFFFFF"></path>
                </g>
                <g transform="translate(123.000000, 0.000000)">
                    <path d="M39,0 L0.1,92.4 L5.4,133 L5.7,132.9 L49.4,34.6 L49.2,32.5 L39.8,1.3 L39,0 L39,0 Z"
                          fill="#66BF6B"></path>
                    <path d="M39,0 L49.1,33.9 L49.3,34.6 L145.5,77.7 L145.8,77.5 L137.6,45.1 L39,0 L39,0 Z"
                          fill="#98D0A0"></path>
                    <path d="M49.1,33.9 L145.8,77.5 L102.1,176.6 L5.4,133 L49.1,33.9 L49.1,33.9 Z"
                          fill="url(#linearGradient-3)"></path>
                    <path
                        d="M116.5,85.5 C114.4,79.9 111.5,75.1 107.7,71.1 C103.9,67.1 99.3,63.9 93.9,61.6 C88.5,59.3 83.1,58.2 77.6,58.2 L77.3,58.2 C71.7,58.2 66.2,59.5 60.8,61.9 L60.8,61.9 C55.1,64.4 50.2,67.8 46,72.3 C41.8,76.7 38.4,82.1 35.8,88.3 C33.2,94.4 31.8,100.6 31.5,106.7 L31.5,108.8 C31.5,114.2 32.4,119.5 34.3,124.7 C36.3,130.2 39.2,134.9 43,138.9 C46.8,142.9 51.5,146.1 57.1,148.4 L57.1,148.4 C62.4,150.7 67.8,151.8 73.3,151.8 L73.4,151.8 C78.9,151.8 84.4,150.6 89.8,148.3 L89.8,148.3 C95.5,145.8 100.5,142.3 104.7,137.8 C108.9,133.4 112.4,128.1 115,121.9 C117.6,115.7 119,109.6 119.3,103.5 L119.3,101.7 C119.4,96.2 118.5,90.8 116.5,85.5 L116.5,85.5 L116.5,85.5 Z M97.2,114.3 C93.6,122.9 88.7,128.8 82.8,132 C79.6,133.7 76.3,134.6 73,134.6 C70.1,134.6 67,133.9 63.9,132.6 C57.1,129.7 52.9,125.1 51.1,118.5 C50.5,116.3 50.2,114 50.2,111.6 C50.2,106.8 51.4,101.5 53.8,95.8 C57.5,87 62.4,81 68.3,77.7 C71.5,75.9 74.8,75.1 78.1,75.1 C81.1,75.1 84.1,75.8 87.3,77.1 C94,80 98.2,84.6 100,91.2 C100.6,93.3 100.9,95.6 100.9,98 C100.9,103 99.7,108.4 97.2,114.3 L97.2,114.3 L97.2,114.3 Z"
                        fill="#FFFFFF"></path>
                </g>
                <g transform="translate(0.000000, 15.000000)">
                    <path
                        d="M115.4,0.7 L131.2,106.5 L124,143.7 L123,142.4 L107.6,40.2 L107.6,38.2 L114.4,2.5 L115.4,0.7 L115.4,0.7 Z"
                        fill="#760D16"></path>
                    <path d="M0,55.4 L7.5,18 L115.4,0.7 L108.1,38.8 L108.1,41.3 L1.3,56.4 L0,55.4 L0,55.4 Z"
                          fill="#EF463E"></path>
                    <path d="M108.1,38.8 L124,143.7 L17.2,160.4 L0,55.4 L108.1,38.8 L108.1,38.8 Z"
                          fill="url(#linearGradient-4)"></path>
                    <path
                        d="M96.6,127 C95.8,126 94.6,125.3 93.2,124.8 C91.8,124.3 90.1,124.1 88,124.1 C86.1,124.1 83.9,124.3 81.5,124.7 L53.3,129.5 C53.6,127.3 54.7,124.5 56.6,121 C58.7,117.1 61.9,112.4 66,107 L66,107 C67.4,105.1 68.5,103.7 69.3,102.7 C69.8,102 70.6,101.1 71.6,99.8 C78.1,91.3 82,84.4 83.6,79 C84.5,75.9 85,72.8 85.2,69.7 C85.3,68.8 85.3,68 85.3,67.2 C85.3,65 85.1,62.8 84.7,60.6 C84.4,58.6 83.9,57 83.2,55.7 C82.5,54.4 81.7,53.4 80.7,52.8 C79.6,52.1 77.9,51.8 75.8,51.8 C74.1,51.8 72,52 69.5,52.4 L36.9,58 C33,58.7 30,59.8 28.2,61.6 C26.7,63 26,64.8 26,66.8 C26,67.3 26,67.9 26.1,68.5 C26.6,71.3 28,73.3 30.3,74.3 C31.7,74.9 33.3,75.2 35.3,75.2 C36.6,75.2 38.1,75.1 39.7,74.8 L66.9,70 C66.9,70.5 67,71 67,71.4 C67,73.1 66.7,74.8 66.1,76.4 C65.3,78.7 63.3,81.9 60,86 C59.1,87.1 57.7,88.9 55.8,91.2 C48.4,100.1 43.2,107.7 40,114 L40,114 C37.7,118.4 36.2,122.6 35.3,126.9 C34.8,129.4 34.5,131.7 34.5,134 C34.5,135.6 34.6,137.2 34.9,138.7 C35.3,140.9 35.8,142.7 36.5,144.1 C37.2,145.5 38.2,146.6 39.3,147.2 C40.4,147.8 41.9,148 44.1,148 C46.8,148 50.5,147.6 55.2,146.8 L84.8,141.7 C90,140.8 93.7,139.5 95.8,137.8 C97.5,136.4 98.4,134.5 98.4,132.3 C98.4,131.7 98.3,131.1 98.2,130.5 C98,129.2 97.5,128 96.6,127 L96.6,127 L96.6,127 Z"
                        fill="#FFFFFF"></path>
                </g>
            </g>
        </g>
    </svg>
}