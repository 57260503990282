type BackendUrls = {
    baseUrl : string;
    loginUrl : string;
};

const UrlsEnv : BackendUrls = {
    baseUrl : (process.env.REACT_APP_BACKEND_BASE_URL as string)  || "http://localhost:8080",
    loginUrl: (process.env.REACT_APP_BACKEND_LOGIN_URL as string)  || "http://localhost:8080/login/",
}

export const CheckUserLoggedInCookie= "Authorization"
export {UrlsEnv}