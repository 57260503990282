import {Step, Stepper, Typography} from "@material-tailwind/react";
import React from "react";
import {StatusProps} from "../../types/applications/types";

export default function StepsProgressBar({statuses}: { statuses: StatusProps[] }) {
    if (statuses.length == 1){
        const lastStage : StatusProps = {
            status: "Selected",
            original_mail: "",
            received_on:"",
            last_updated_on: ""
        }
        statuses.push(lastStage)
    }
    const [activeStep, setActiveStep] = React.useState(statuses.length-2);
    const [isLastStep, setIsLastStep] = React.useState(false);
    const [isFirstStep, setIsFirstStep] = React.useState(false);
    return (
        <div className="w-full p-2">
            <Stepper
                activeStep={activeStep}
                isLastStep={(value) => setIsLastStep(value)}
                isFirstStep={(value) => setIsFirstStep(value)}
                lineClassName="bg-green-100/50"
                activeLineClassName="bg-green-300"
            >
                {statuses && statuses.length > 0 && statuses.map((status, index) => {
                    return (<Step activeClassName="ring-2 ring-green-600 !bg-green-400" completedClassName="!bg-green-400" className="h-4 w-4" onClick={() => setActiveStep(index)}>
                        <div className="absolute -bottom-[1.5rem] w-max text-center text-xs">
                            <span className={"text-green-900 text-sm"}>{status.status}</span>
                        </div>
                    </Step>);
                })}
            </Stepper>

        </div>
    );
}