import {Button} from "@material-tailwind/react";

export default function SetPassword() {

    const goToHome = () => {
        window.location.href = '/';
    }
    return (
        <div>
            <h1>Set Password</h1>
            <Button onClick={goToHome}>Go To Home</Button>
        </div>
    )
}