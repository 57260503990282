import {getCookie} from "./cookies";
import {CheckUserLoggedInCookie} from "./constants";
import UserProfile from "../components/ProfileMenu";
import {jwtDecode} from "jwt-decode";

function getAuthenticationStatus(): Boolean {
    const cookie = getCookie(CheckUserLoggedInCookie);
    return cookie!=undefined && cookie !== "";

}

function getUser(): UserProfile {
    let user: UserProfile = {
        name: "",
        email: "",
        avatar: "",
        role: "",
        id: "",
        username: ""
    }

    try {
        const cookie = getCookie(CheckUserLoggedInCookie);
        if (!cookie) return user
        const payload = jwtDecode(cookie);
        return payload as UserProfile
    } catch (error: any) {
        console.log(error);
        return user
    }
}

export {getAuthenticationStatus, getUser};
