import React, {useEffect, useState} from 'react';
import {StickyNavbar} from "../components/navbar/StickyNavBar";
import {UrlsEnv} from "../utils/constants";
import {toast} from "react-toastify";
import {Typography} from "@material-tailwind/react";
import ApplicationDetails from "../components/stages/ApplicationDetails";

export default function Applications() {
    const [allJobsData, setAllJobsData] = useState([]);

    useEffect(() => {
        fetch(`${UrlsEnv.baseUrl}/user/get-all-jobs-with-status`, {
            credentials: "include",
        }).then((res) => res.json()).then((data) => {
            setAllJobsData(data)
        }).catch((error) => toast.error(error.toString()))
    }, [])

    return (
        <div>
            <StickyNavbar/>
            <div>
                <div className={"m-2 p-2"}>
                    <Typography color={"blue"} variant="h5">Status of All Your Applications</Typography>
                </div>
                {allJobsData && allJobsData.length ? allJobsData.map((application: any) => {
                    return (
                        <ApplicationDetails application={application}/>
                    )
                }) : <Typography color={"blue"} variant="h5">No Applications</Typography>}
            </div>
        </div>
    );
}