import {Button} from "@material-tailwind/react";
import {UrlsEnv} from "../utils/constants";
import {useEffect} from "react";
import {getAuthenticationStatus} from "../utils/authentication";
import {useNavigate} from "react-router-dom";
import {StickyNavbar} from "../components/navbar/StickyNavBar";
import ZohoSVGLogo from "../components/assets/icons/svg/zoho";
import GoogleSVGLogo from "../components/assets/icons/svg/google";

export default function Login() {
    const navigate = useNavigate()
    const handleLogin = (e: any) => {
        window.location.href = (UrlsEnv.loginUrl + e.target.id)
    }

    useEffect(() => {
        const authorization = getAuthenticationStatus();
        if (authorization) {
            navigate("/")
        }
    }, [])

    return (
        <div className="min-h-screen flex flex-col">
            <StickyNavbar/>
            <div className="flex-grow flex flex-col items-center justify-center p-2">
                <div className="w-3/4">
                    <Button onClick={handleLogin} variant={"outlined"} id={"google"} fullWidth={true}
                            className={" text-md mb-8 flex align-middle justify-center items-center gap-x-4 hover:bg-gray-50 hover:scale-105"}>
                        <GoogleSVGLogo/>
                        Login with Google
                    </Button>
                    <Button onClick={handleLogin} variant={"outlined"}
                            className={"text-md flex align-middle justify-center items-center gap-x-4 hover:bg-gray-50 hover:scale-105"}
                            id={"zoho"}
                            fullWidth={true}>
                        <ZohoSVGLogo/> Login with Zoho
                    </Button>
                </div>
            </div>
        </div>
    );

}