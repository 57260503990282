import Cookies from "js-cookie";

function getCookie (name : string) : string {
    const cookie = Cookies.get(name);
    if (cookie === undefined) {
        return "";
    }
    return cookie;
}

export {getCookie}