import React from "react";
import {
    Button,
    Collapse,
    IconButton,
    List,
    ListItem,
    Menu,
    MenuHandler,
    MenuItem,
    MenuList,
    Navbar,
    Typography,
} from "@material-tailwind/react";

import {
    Bars4Icon,
    GlobeAmericasIcon,
    NewspaperIcon,
    PhoneIcon,
    RectangleGroupIcon,
    SquaresPlusIcon,
    SunIcon,
    TagIcon,
    UserGroupIcon,
} from "@heroicons/react/24/solid";
import {Bars3Icon, ChevronDownIcon, XMarkIcon} from "@heroicons/react/16/solid";
import TJO_Logo from '../assets/icons/tjoLogo.png';
import {useLocation, useNavigate} from "react-router-dom";
import UserProfile, {ProfileMenu} from "../ProfileMenu";
import {getUser} from "../../utils/authentication";

const navListMenuItems = [
    {
        title: "Products",
        description: "Find the perfect solution for your needs.",
        icon: SquaresPlusIcon,
    },
    {
        title: "About Us",
        description: "Meet and learn about our dedication",
        icon: UserGroupIcon,
    },
    {
        title: "Blog",
        description: "Find the perfect solution for your needs.",
        icon: Bars4Icon,
    },
    {
        title: "Services",
        description: "Learn how we can help you achieve your goals.",
        icon: SunIcon,
    },
    {
        title: "Support",
        description: "Reach out to us for assistance or inquiries",
        icon: GlobeAmericasIcon,
    },
    {
        title: "Contact",
        description: "Find the perfect solution for your needs.",
        icon: PhoneIcon,
    },
    {
        title: "News",
        description: "Read insightful articles, tips, and expert opinions.",
        icon: NewspaperIcon,
    },
    {
        title: "Products",
        description: "Find the perfect solution for your needs.",
        icon: RectangleGroupIcon,
    },
    {
        title: "Special Offers",
        description: "Explore limited-time deals and bundles",
        icon: TagIcon,
    },
];

function NavListMenu() {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
    const renderItems = navListMenuItems.map(
        ({icon, title, description}, key) => (
            <a href="#" key={key}>
                <MenuItem className="flex items-center gap-3 rounded-lg">
                    <div className="flex items-center justify-center rounded-lg !bg-blue-gray-50 p-2 ">
                        {" "}
                        {React.createElement(icon, {
                            strokeWidth: 2,
                            className: "h-6 text-gray-900 w-6",
                        })}
                    </div>
                    <div>
                        <Typography
                            variant="h6"
                            color="blue-gray"
                            className="flex items-center text-sm font-bold"
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="paragraph"
                            className="text-xs !font-medium text-blue-gray-500"
                        >
                            {description}
                        </Typography>
                    </div>
                </MenuItem>
            </a>
        ),
    );

    return (
        <React.Fragment>
            <Menu
                open={isMenuOpen}
                handler={setIsMenuOpen}
                offset={{mainAxis: 20}}
                placement="bottom"
                allowHover={true}
            >
                <MenuHandler>
                    <Typography as="div" variant="small" className="font-medium">
                        <ListItem
                            className="flex items-center gap-2 py-2 pr-4 font-medium text-gray-900"
                            selected={isMenuOpen || isMobileMenuOpen}
                            onClick={() => setIsMobileMenuOpen((cur) => !cur)}
                        >
                            Resources
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`hidden h-3 w-3 transition-transform lg:block ${
                                    isMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`block h-3 w-3 transition-transform lg:hidden ${
                                    isMobileMenuOpen ? "rotate-180" : ""
                                }`}
                            />
                        </ListItem>
                    </Typography>
                </MenuHandler>
                <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
                    <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
                        {renderItems}
                    </ul>
                </MenuList>
            </Menu>
            <div className="block lg:hidden">
                <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
            </div>
        </React.Fragment>
    );
}

interface NavListProps {
    path: string;
}

function NavList({path}: NavListProps) {
    if (path === "/login") {
        return <></>
    }
    return (
        <List className="w-full justify-end mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
            <Typography
                as="a"
                href="/"
                variant="small"
                color="blue-gray"
                className="font-medium"
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">Home</ListItem>
            </Typography>
            {/*<NavListMenu />*/}
            {path !== "/applications" && <Typography
                as="a"
                href="/dashboard"
                variant="small"
                color="blue-gray"
                className="font-medium"
            >
                <ListItem className="flex items-center gap-2 py-2 pr-4">
                    My Applications
                </ListItem>
            </Typography>}
        </List>
    );
}

export function StickyNavbar() {
    const [openNav, setOpenNav] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const u: UserProfile = getUser();

    const session = u.email !== ""

    return (<Navbar fullWidth={true} className="navbar mx-auto px-4 py-1 sticky top-0 z-50 max-h-15" shadow={true}>
        <div className="flex items-center justify-between text-blue-gray-900">
            <a href="/" className="cursor-pointer">
                <img src={TJO_Logo} alt="tjo jobs logo" className="max-h-10"/>
            </a>

            <div className="flex items-center justify-end">
                <div className="hidden md:block">
                    <NavList path={location.pathname}/>
                </div>
                {session && <Typography variant="h6" color={"red"} className="p-1" size="sm">Hi, {u.name.split(" ").length>0 ? u.name.split(" ")[0] : "" }</Typography>}
                {session && <ProfileMenu user={u}/>}
                <IconButton
                    variant="text"
                    color="blue-gray"
                    className="md:hidden"
                    onClick={() => setOpenNav(!openNav)}
                >
                    {openNav ? (<XMarkIcon className="h-6 w-6" strokeWidth={2}/>) : (
                        <Bars3Icon className="h-6 w-6" strokeWidth={2}/>)}
                </IconButton>
                <div className="hidden gap-2 md:flex">
                    {!session && location.pathname !== "/login" && (<Button
                        variant="gradient"
                        size="sm"
                        onClick={() => {
                            navigate(`/login`)
                        }}
                    >
                        Sign In
                    </Button>)}
                </div>
            </div>
        </div>
        <Collapse open={openNav}>
            <NavList path={location.pathname}/>
            <div className="flex w-full flex-nowrap items-center gap-2 md:hidden">
                {!session && location.pathname !== "/login" && (<Button
                    variant="gradient"
                    size="sm"
                    onClick={() => {
                        navigate(`/login`)
                    }}
                >
                    Sign In
                </Button>)}
            </div>
        </Collapse>
    </Navbar>);
}