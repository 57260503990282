import {Accordion, AccordionBody, Typography} from '@material-tailwind/react';
import React, {useState} from 'react';
import companyIcon from '../assets/icons/svg/companyIcon.svg';
import locationIcon from '../assets/icons/svg/locationIcon.svg';
import {ApplicationStatusProps} from "../../types/applications/types";
import StepsProgressBar from "./StepsProgessBar";

function Icon({id, open}: { id: string, open: string }) {
    return (
        <div className={"w-fit"}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
            </svg>
        </div>
    );
}


export default function ApplicationDetails({application}: { application: ApplicationStatusProps }) {
    const [open, setOpen] = useState("");
    return (<div className={"p-2 m-4 border-gray-100 border-2 rounded-md hover:bg-red-100/10"}>
        <Accordion open={open === "1"} icon={<Icon id={open.toString()} open={open.toString()}/>}>
            <div onClick={() => setOpen(open === "1" ? "" : "1")} className={"flex hover:cursor-pointer align-middle"}>
                <img alt={"company name"} src={"/logo192.png"} className={"max-w-20 max-h-20 p-1"}/>
                <div className="w-full ml-4 p-1 flex flex-col gap-0.5">
                    <Typography variant="h6">{application.role}</Typography>
                    <span className={"text-sm flex items-center"}>
                        <img className={"max-h-3.5 mr-1"} src={companyIcon}
                             alt={"company-icon"}/>{application.company_name}</span>
                    {application.location && <span className={"text-sm flex items-center"}>
                        <img className={"max-h-3.5 mr-1"} src={locationIcon}
                             alt={"company-icon"}/>{application.location}</span>}
                </div>
                <StepsProgressBar statuses={application.statuses}/>
                <Icon open={open} id={"1"}/>
            </div>
            <AccordionBody>
                {application.statuses && application.statuses.length &&
                    <div>{application.statuses[0].original_mail}</div>}
            </AccordionBody>
        </Accordion>

    </div>)
}
